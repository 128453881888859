/* -----------------------------------------------------------------------------

  RELATED CONTENT
  
----------------------------------------------------------------------------- */

.related-content {
	&__link { display: block; display: flex; align-items: center; text-decoration: none; color: inherit; background: #fff; transition: background .25s ease, color .25s ease; border-radius: 3px; overflow: hidden;
		img { width: 25%; margin-right: 15px; }
	}
}