/* -----------------------------------------------------------------------------

  BUTTONS
  
----------------------------------------------------------------------------- */

.btn { display: inline-block; padding: 1rem 2rem; margin-bottom: 0; line-height: 1.45; text-align: center; white-space: nowrap; vertical-align: middle; touch-action: manipulation; cursor: pointer; user-select: none;background-image: none; border: .1rem solid transparent; border-radius: .5rem; text-decoration: none; transition: background .2s, border-color .2s, color .2s; font-family: 'Roboto', sans-serif; text-transform: uppercase; }

.btn { color: #fff; background-color: #d6556e; border-color: #d6556e; font-size: 1.6rem;  }
.btn:active,
.btn:focus,
.btn:hover { color: #333; background-color: #e6e6e6; border-color: #adadad; }
.btn:hover {  }
.btn:active {  }
.btn svg { fill: $btn-primary-colour; }


.btn--primary { color: $btn-primary-colour; background-color: $btn-primary-bg; border-color: $btn-primary-border; box-shadow: 0 3px 0 0 $btn-primary-border; }
.btn--primary:active, 
.btn--primary:focus, 
.btn--primary:hover { color: $btn-primary-focus-colour;background-color: $btn-primary-focus-bg; border-color: $btn-primary-focus-border }
.btn--primary:hover { box-shadow: 0 4px 0 0 $btn-primary-border; position: relative; top: -1px; }
.btn--primary:active { box-shadow: 0 1px 0 0 $btn-primary-border; position: relative; top: 2px; }
.btn--primary svg { fill: $btn-primary-colour; }

.btn--plain { font-family: 'Raleway', sans-serif; font-size: 1.2rem; color: inherit; text-decoration: none;
    .icon { width: .8rem; height: .8rem; }
}