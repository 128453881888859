/* -----------------------------------------------------------------------------

  FORMS
  
----------------------------------------------------------------------------- */

fieldset { border: none; }
legend { font-size: 1.4em; margin: 0 0 20px; border-bottom: 1px solid #ccc; padding: 0 0 10px; display: block; width: 100%; }

/*--
LABELS
--*/

label { display: block; margin: 0 0 3px; 
	&.has-error { color: $form-invalid; }
}

/*--
INPUTS
--*/

input, textarea, select { -moz-appearance: none; -webkit-appearance: none; }
textarea { height: 10rem; resize:vertical; }
select { position: relative; background: #fff url(../img/select-arrow.png) no-repeat center right .5rem; border-radius: .3rem; padding: 0 3rem 0 1rem; }


.standard-form-control { padding: $form-input-padding; background: $form-input-background-colour; transition: $form-input-transition; max-width: 40rem; width: 100%; outline-width: 0; border: none;
    &.standard-form-select  { background: #fff url(../img/select-arrow.png) no-repeat center right 1rem; padding-right: 40px; }
    &:focus { border-color: $form-input-border-focus; box-shadow: 0 0 1rem rgba(0,0,0,.1); }
    &.success { border-color: $form-valid; }
    &.has-error { border-color: $form-invalid; }
}
em.has-error { font-style: normal; color: $form-invalid; font-size: 75%; padding: .3rem 0 0; position: absolute; left: 0; bottom: -.7rem; background: #fff;
	textarea + & { vertical-align: top; }
}

.summary-of-errors { color: $form-invalid; }

.form-has-error { position: relative; }

@media only screen and (max-width: $sm-width-under) {
.standard-form-control { font-size: 1.6rem; }
}

/*--
DISABLED
--*/

.standard-form-control:disabled { opacity: .3; }