

.person { padding: 0; color: #121212; background: #fff; margin: 0 2rem 2rem; box-shadow: 1px 1px 1px rgba(0,0,0,.1); border-radius: 3px; overflow: hidden; padding: 2rem; }
.person-image-holder { padding-bottom: 100%; display: block; background: #ededed; position: relative;
    img { position: absolute; left: 0; top: 0; width: 100%; }
}
.default-image-text { position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); }

@media screen and (min-width: $md-width) {
    .people-list { display: flex; flex-wrap: wrap; margin: 0 2rem; justify-content: center; }
    .person { width: calc(33.3333% - 4rem); margin: 0 2rem 4rem; }
}