/* -----------------------------------------------------------------------------

  WIDGETS
  
----------------------------------------------------------------------------- */

.widget { margin: 0 0 20px; }

@media screen and (min-width: 1024px) {
    .widget { margin: 0 0 40px; }
}


.widget--video { border-radius: 3px; overflow: hidden; }

.widget--snippet { background: #ededed; padding: 20px;
    h2 { margin: 0 0 10px; }
    p:last-of-type { margin: 30px 0 0; }
}
@media screen and (min-width: 1024px) {
    .widget--snippet { background: #ededed; padding: 40px; margin: 0 0 40px 50px; border-radius: 3px;
        h2 { margin: 0 0 10px; }
        p:last-of-type { margin: 30px 0 0; }
    }
    
}
@media screen and (min-width: $xl-width) {
    .widget--text { 
        p { margin-left: 50px; }
    }    
}

.widget--section-title { margin: 50px 0 40px;
    h2 { margin: 0; color: rgb(214, 85, 110); }
}

.widget--image {
    img { width: 100%; height: auto; border-radius: 3px; }   
}

.widget--quote { background-image: linear-gradient(19deg, rgb(214, 85, 110) 0%, rgb(31, 36, 161) 100%); background: #f6f6f6; color: rgb(214, 85, 110); padding: 20px; border-radius: 3px; max-width: 600px;
    blockquote { padding: 0; margin: 0;
        p { line-height: 1.2; font-size: 2.4em; }
    }
    footer { border-top: 1px dotted rgb(214, 85, 110); padding: 10px 0 0; font-size: 1.4em; font-family: 'Roboto', sans-serif; }
}

@media screen and (min-width: 1024px) {
 .widget--quote { padding: 40px; margin: 0 0 40px 50px; 
     footer { margin: 20px 0 0; padding: 20px 0 0; }
 }
 .widget--image + .widget--quote { margin: -100px 0 40px 50px; position: relative; z-index: 2; }
 .widget--section-title { margin-left: 50px; }
}