/* -----------------------------------------------------------------------------

  PASSWORD INPUT METER

----------------------------------------------------------------------------- */
[v-cloak] {
  display: none;
}
.Password {
  max-width:  400px;
  margin:  0 auto;
}
.Password__group {
  position: relative;
}
.Password__strength-meter {
  position: relative;
  height: 3px;
  background: #DDD;
  margin: 10px auto 20px;
  border-radius: 3px;
}
.Password__strength-meter:before, .Password__strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #FFF;
  border-style: solid;
  border-width: 0 5px 0 5px;
  position: absolute;
  width: 80px;
  z-index: 10;
}
.Password__strength-meter:before {
  left: 70px;
}
.Password__strength-meter:after {
  right: 70px;
}
.Password__strength-meter--fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}
.Password__strength-meter--fill[data-score='0'] {
  background: darkred;
  width: 20%;
}
.Password__strength-meter--fill[data-score='1'] {
  background: orangered;
  width: 40%;
}
.Password__strength-meter--fill[data-score='2'] {
  background: orange;
  width: 60%;
}
.Password__strength-meter--fill[data-score='3'] {
  background: yellowgreen;
  width: 80%;
}
.Password__strength-meter--fill[data-score='4'] {
  background: green;
  width: 100%;
}
.Password__field {
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 13px;
  width: 100%;
}
.Password__badge {
  float: right;
  position: relative;
  bottom: 33px;
  right: 10px;
  color: white;
  padding: 2px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 1.1;
}
.Password__badge--error {
  background: red;
}
.Password__badge--success {
  background: #1bbf1b;
}
