/*! Typography */
html { font-size: 62.5% !important; }

h1, h2, h3, h4, h5, h6, h7 { font-weight: normal; margin: 0 0 15px; line-height: 1.2; font-family: 'Roboto', sans-serif; }

h1, .h1 { font-size: 3.6em; line-height: 1.16; }
h2, .h2 { font-size: 2.4em; line-height: 1.25; }
h3, .h3 { font-size: 1.8em; line-height: 1.33; }
h4, .h4 { font-size: 1.6em; line-height: 1.375; }
h5, .h5 { font-size: 1.4em; line-heigh: 1.43; }
h6, .h6 { font-size: 1.2em; line-height: 1.5; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small { font-size: 65%; color: #666; }

*+h1, *+h2, *+h3, *+h4, *+h5, *+h6 { margin-top: 25px; }

p, li, dl, address { font-size: 1.8em; margin: 0 0 10px; line-height: 1.55; font-family: 'Goudy Bookletter 1911', Georgia, Times, serif; }
p.lead { font-size: 2em; }

address { font-style: normal; }

a { color: $link-colour; }
a:hover { text-decoration: none; }
.link-reset { color: inherit; text-decoration: none; }

/* Lists */

ul, ol { margin: 0; padding: 0 0 0 20px; }
ul ul, ol ol, ul ol, ol ul { padding: 15px 0 0 40px; }
ol ol { list-style: lower-roman; } 
li li { font-size: 1em; }
nav li { list-style: none; }

.reset-list { list-style: none; margin: 0; padding: 0; }

hr { border: 0; border-top: 1px solid #ccc; margin: 30px 0; }

dl { margin: 0 0 15px; }
dt { font-weight: 700; }
dd { margin: 0; }
dt, dd { line-height: 1.45; }

pre { padding: 10px; background: #fafafa; font: 1.4em/1.4 Consolas, monospace, serif; color: #666; -moz-tab-size: 4; tab-size: 4; overflow: auto; border: 1px solid #e5e5e5; border-radius: 3px; }

.dl-horizontal dt { clear: left; float: left; overflow: hidden; text-align: right; text-overflow: ellipsis; white-space: nowrap; width: 160px; }
.dl-horizontal dd { margin-left: 180px; }
.dl-horizontal dd:before { content: ''; display: table; }

.badge { display: inline-block; padding: 0 5px; background: #009dd8; font-size: 80%; font-weight: 700; line-height: 1.2; color: #fff; text-align: center; vertical-align: middle; text-transform: none; border: 1px solid rgba(0, 0, 0, .2); border-bottom-color: rgba(0, 0, 0, .3); background-origin: border-box; background-image: linear-gradient(to bottom, #00b4f5, #008dc5); border-radius: 2px; text-shadow: 0 -1px 0 rgba(0, 0, 0, .2); text-transform: uppercase; }

.badge-danger { background: #C9181B; }