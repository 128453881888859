@charset "UTF-8";
/* CSS Document */
/* Variables */
/*
Z-INDEX MAP

999 - Slick slides
1000 - Dropdown navigation
1300 - Fixed Header
1500 - Responsive navigation overlay
1501 - Responsive navigation trigger
1999 - Overlay BG
2000 - Overlays

*/
/*! Typography */
html {
  font-size: 62.5% !important;
}

h1, h2, h3, h4, h5, h6, h7 {
  font-weight: normal;
  margin: 0 0 15px;
  line-height: 1.2;
  font-family: 'Roboto', sans-serif;
}

h1, .h1 {
  font-size: 3.6em;
  line-height: 1.16;
}

h2, .h2 {
  font-size: 2.4em;
  line-height: 1.25;
}

h3, .h3 {
  font-size: 1.8em;
  line-height: 1.33;
}

h4, .h4 {
  font-size: 1.6em;
  line-height: 1.375;
}

h5, .h5 {
  font-size: 1.4em;
  line-heigh: 1.43;
}

h6, .h6 {
  font-size: 1.2em;
  line-height: 1.5;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-size: 65%;
  color: #666;
}

* + h1, * + h2, * + h3, * + h4, * + h5, * + h6 {
  margin-top: 25px;
}

p, li, dl, address {
  font-size: 1.8em;
  margin: 0 0 10px;
  line-height: 1.55;
  font-family: 'Goudy Bookletter 1911', Georgia, Times, serif;
}

p.lead {
  font-size: 2em;
}

address {
  font-style: normal;
}

a {
  color: #900;
}

a:hover {
  text-decoration: none;
}

.link-reset {
  color: inherit;
  text-decoration: none;
}

/* Lists */
ul, ol {
  margin: 0;
  padding: 0 0 0 20px;
}

ul ul, ol ol, ul ol, ol ul {
  padding: 15px 0 0 40px;
}

ol ol {
  list-style: lower-roman;
}

li li {
  font-size: 1em;
}

nav li {
  list-style: none;
}

.reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

dl {
  margin: 0 0 15px;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0;
}

dt, dd {
  line-height: 1.45;
}

pre {
  padding: 10px;
  background: #fafafa;
  font: 1.4em/1.4 Consolas, monospace, serif;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  overflow: auto;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}

.dl-horizontal dt {
  clear: left;
  float: left;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
}

.dl-horizontal dd {
  margin-left: 180px;
}

.dl-horizontal dd:before {
  content: '';
  display: table;
}

.badge {
  display: inline-block;
  padding: 0 5px;
  background: #009dd8;
  font-size: 80%;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  text-transform: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.3);
  background-origin: border-box;
  background-image: linear-gradient(to bottom, #00b4f5, #008dc5);
  border-radius: 2px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

.badge-danger {
  background: #C9181B;
}

/* -----------------------------------------------------------------------------

  HELPERS
  
----------------------------------------------------------------------------- */
img {
  max-width: 100%;
  height: auto;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after,
.clear-both {
  clear: both;
}

.hide {
  display: none;
}

.text-align-right {
  text-align: right;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.relative {
  position: relative;
}

.reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reset-link {
  color: inherit;
  text-decoration: none;
}

/* -----------------------------------------------------------------------------

  FORMS
  
----------------------------------------------------------------------------- */
fieldset {
  border: none;
}

legend {
  font-size: 1.4em;
  margin: 0 0 20px;
  border-bottom: 1px solid #ccc;
  padding: 0 0 10px;
  display: block;
  width: 100%;
}

/*--
LABELS
--*/
label {
  display: block;
  margin: 0 0 3px;
}

label.has-error {
  color: #FF665A;
}

/*--
INPUTS
--*/
input, textarea, select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

textarea {
  height: 10rem;
  resize: vertical;
}

select {
  position: relative;
  background: #fff url(../img/select-arrow.png) no-repeat center right 0.5rem;
  border-radius: .3rem;
  padding: 0 3rem 0 1rem;
}

.standard-form-control {
  padding: 1rem;
  background: #fff;
  transition: border-color 0.2s, box-shadow 0.2s;
  max-width: 40rem;
  width: 100%;
  outline-width: 0;
  border: none;
}

.standard-form-control.standard-form-select {
  background: #fff url(../img/select-arrow.png) no-repeat center right 1rem;
  padding-right: 40px;
}

.standard-form-control:focus {
  border-color: #79DCE8;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.standard-form-control.success {
  border-color: #AEE88A;
}

.standard-form-control.has-error {
  border-color: #FF665A;
}

em.has-error {
  font-style: normal;
  color: #FF665A;
  font-size: 75%;
  padding: .3rem 0 0;
  position: absolute;
  left: 0;
  bottom: -.7rem;
  background: #fff;
}

textarea + em.has-error {
  vertical-align: top;
}

.summary-of-errors {
  color: #FF665A;
}

.form-has-error {
  position: relative;
}

@media only screen and (max-width: 479px) {
  .standard-form-control {
    font-size: 1.6rem;
  }
}

/*--
DISABLED
--*/
.standard-form-control:disabled {
  opacity: .3;
}

/*! Header */
.site-header {
  padding: 20px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.site-logo {
  font-family: 'Suez One', serif;
  font-size: 3em;
  background: #fff;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d6556e;
  border-radius: 50%;
  text-decoration: none;
}

@media screen and (min-width: 480px) {
  .site-logo {
    font-size: 6em;
    height: 90px;
    width: 90px;
  }
}

.home-panel {
  background-image: linear-gradient(19deg, #d6556e 0%, #1f24a1 100%);
  height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-panel h1 {
  color: #f6f6f6;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-size: 40px;
}

.home-panel h1 span {
  color: #fff;
}

@media screen and (min-width: 480px) {
  .home-panel {
    font-size: 60px;
  }
}

.home-links {
  list-style: none;
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
}

.home-links li {
  padding: 20px;
  width: 50%;
}

.home-links a {
  display: flex;
  height: 200px;
  padding: 20px;
  color: #121212;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  background: #fff;
  line-height: 1;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  border-radius: .3rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 480px) {
  .home-links {
    margin: 60px;
  }
  .home-links li {
    width: 20%;
  }
}

/* -----------------------------------------------------------------------------

  OVERLAYS
  
----------------------------------------------------------------------------- */
.overlay-bg {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.25s,opacity 0.25s linear;
  z-index: 1999;
}

.overlay-bg.overlay-bg--visible {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 560px;
  height: 500px;
  background: #fff;
  transform: scale(0.5, 0.5) translate(-50%, -50%);
  transition: visibility 0s linear 0.25s,opacity 0.25s linear,transform 0.25s linear;
  z-index: 2000;
  transform-origin: left top;
}

.overlay.overlay--visible {
  visibility: visible;
  opacity: 1;
  transform: scale(1, 1) translate(-50%, -50%);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  transition-delay: 0s;
}

.overlay--massive {
  width: 300px;
  height: auto;
  margin: -500px 0 0 -150px;
}

.overlay--modal {
  width: 320px;
  height: 230px;
  margin: -115px 0 0 -160px;
  text-align: center;
}

.overlay--modal__actions {
  padding: 20px 0;
}

.overlay--modal__actions li {
  display: inline-block;
  margin: 0 20px;
}

.overlay__content-holder--modal {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.overlay__modal-text {
  flex-grow: 1;
}

.overlay.overlay--full {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  position: fixed;
}

.overlay--fixed {
  position: fixed;
}

.overlay--fixed.overlay--tall {
  top: 20px;
  bottom: 20px;
  height: auto;
  margin-top: 0;
}

.overlay__close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 15px;
  z-index: 2000;
}

.overlay__content-holder {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding: 50px 20px 20px;
}

@media screen and (min-width: 768px) {
  .main-content {
    transition: filter .25s ease;
  }
  .overlay-showing .main-content {
    filter: grayscale(80%);
  }
}

@media screen and (min-width: 768px) {
  .overlay--video {
    max-width: 1024px;
    height: auto;
    width: 100%;
  }
}

/* -----------------------------------------------------------------------------

  BUTTONS
  
----------------------------------------------------------------------------- */
.btn {
  display: inline-block;
  padding: 1rem 2rem;
  margin-bottom: 0;
  line-height: 1.45;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: .1rem solid transparent;
  border-radius: .5rem;
  text-decoration: none;
  transition: background .2s, border-color .2s, color .2s;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}

.btn {
  color: #fff;
  background-color: #d6556e;
  border-color: #d6556e;
  font-size: 1.6rem;
}

.btn:active,
.btn:focus,
.btn:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn svg {
  fill: #fff;
}

.btn--primary {
  color: #fff;
  background-color: #d6556e;
  border-color: #c8304e;
  box-shadow: 0 3px 0 0 #c8304e;
}

.btn--primary:active,
.btn--primary:focus,
.btn--primary:hover {
  color: #fff;
  background-color: #d1405c;
  border-color: #b32b46;
}

.btn--primary:hover {
  box-shadow: 0 4px 0 0 #c8304e;
  position: relative;
  top: -1px;
}

.btn--primary:active {
  box-shadow: 0 1px 0 0 #c8304e;
  position: relative;
  top: 2px;
}

.btn--primary svg {
  fill: #fff;
}

.btn--plain {
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  color: inherit;
  text-decoration: none;
}

.btn--plain .icon {
  width: .8rem;
  height: .8rem;
}

/* -----------------------------------------------------------------------------

  LAZYLOAD
  
----------------------------------------------------------------------------- */
.lazyload {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 1s;
}

/* -----------------------------------------------------------------------------

  IMAGE SLIDER

----------------------------------------------------------------------------- */
/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat;
  min-height: 400px;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #d6556e;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.slick-slide .lazyloaded {
  width: 100%;
}

.feature-banner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.feature-banner__control {
  padding: 10px;
  background: #d6556e;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  cursor: pointer;
  transition: all .25s;
  fill: #fff;
}

.feature-banner__control:hover {
  width: 50px;
  height: 50px;
  margin-top: -25px;
}

.feature-banner__control--previous {
  left: -100px;
}

.feature-banner:hover .feature-banner__control--previous {
  left: 25px;
}

.feature-banner:hover .feature-banner__control--previous:hover {
  left: 15px;
}

.feature-banner__control--next {
  right: -100px;
}

.feature-banner:hover .feature-banner__control--next {
  right: 25px;
}

.feature-banner:hover .feature-banner__control--next:hover {
  right: 15px;
}

.feature-banner__text {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100%;
  bottom: -150px;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  color: #fff;
  transition: bottom .75s ease;
  opacity: 0;
}

.slick-current .feature-banner__text {
  bottom: 0;
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .feature-banner__text {
    left: 100px;
    bottom: -250px;
    width: auto;
  }
  .slick-current .feature-banner__text {
    bottom: 50px;
  }
}

/* -----------------------------------------------------------------------------

  SHARE BUTTONS
  
----------------------------------------------------------------------------- */
ul.share-buttons {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

ul.share-buttons li {
  display: inline-block;
  margin: 0 6px 10px 0;
}

ul.share-buttons a {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  color: #fff;
  background: #000;
  border-radius: 3px;
}

/* -----------------------------------------------------------------------------

  NEWS LIST
  
----------------------------------------------------------------------------- */
.page-heading {
  padding: 6rem 2rem 4rem;
  text-align: center;
  background: #d6556e;
  color: #fff;
  margin: 0 0 2rem;
}

.page-heading h1 {
  font-size: 5rem;
  text-transform: uppercase;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .page-heading {
    padding: 15rem 6rem 5rem;
    text-align: center;
    color: #fff;
    margin: 0 0 5rem;
  }
  .page-heading h1 {
    font-size: 7.2rem;
    text-transform: uppercase;
  }
}

.news-teasers {
  padding: 0 0 10rem;
  margin: 0 auto;
}

.news-teaser-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .news-teasers {
    padding: 10rem 0 5rem;
    margin: 0 auto;
    max-width: 40rem;
  }
}

.news-teaser {
  padding: 0;
  color: #121212;
  background: #fff;
  display: flex;
  margin: 0 0 4rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.news-teaser__image-link {
  display: block;
  margin: 0;
  position: relative;
  overflow: hidden;
  width: 50%;
}

.news-teaser__image-link img {
  display: block;
  width: 100%;
}

.news-teaser__hover-content {
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(121, 220, 232, 0.9);
  display: block;
}

.news-teaser__read-more {
  position: absolute;
  display: block;
  padding: 2rem;
  background: #fff;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin: -32px 0 0 -32px;
}

.news-teaser__headline {
  font-size: 3em;
  margin: 0 0 1rem;
  font-weight: 700;
}

.news-teaser__headline a {
  color: #121212;
  text-decoration: none;
}

.news-teaser__article-meta {
  font-size: 1.2em;
  color: #121212;
  position: relative;
  font-family: 'Raleway', sans-serif;
}

.news-teaser__article-meta a {
  color: #333;
  text-decoration: none;
}

.news-teaser__article-meta a:hover {
  text-decoration: underline;
}

.news-teaser__text {
  width: 50%;
  padding: 2rem;
}

@media screen and (min-width: 480px) {
  .news-teaser__text {
    display: flex;
    flex-direction: column;
  }
}

.news-teaser__actions {
  text-align: right;
  padding: 1rem 0 0;
  font-size: 1.8em;
  margin: 0;
}

.news-teaser__max-height {
  position: relative;
}

.news-teaser__max-height:after {
  content: '';
  display: block;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
  height: 3rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

@media screen and (min-width: 480px) {
  .news-teaser__max-height {
    max-height: 211px;
    overflow: hidden;
    flex-grow: 1;
  }
}

.news-teaser--sticky {
  position: relative;
}

.news-teaser--sticky:after {
  content: 'Featured';
  display: block;
  height: 3rem;
  left: -2px;
  top: 1rem;
  position: absolute;
  background: #d6556e;
  line-height: 3rem;
  color: #fff;
  text-transform: uppercase;
  padding: 0 1rem;
  font-size: 1.2em;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.news-teaser__categories a {
  font-size: 1.2rem;
  background: #d6556e;
  padding: .4rem .8rem;
  border-radius: .2rem;
  color: #fff;
  position: relative;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
}

@media screen and (max-width: 479px) {
  .news-teaser {
    width: 100%;
    margin: 0 0 2rem;
    flex-direction: column-reverse;
  }
  .news-teaser__text,
  .news-teaser__image-link {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .news-teasers {
    max-width: none;
  }
  .news-teaser:not(.news-teaser--full-width) {
    width: calc(50% - 4rem);
    max-width: 80rem;
    margin: 0 2rem 4rem;
  }
}

.news-teaser-list--single-row .news-teaser {
  transform: translateY(0) !important;
}

.news-teaser__teaser {
  flex-grow: 1;
}

.news-list-search {
  position: relative;
  width: 100%;
  border-radius: .5rem;
  overflow: hidden;
}

.news-list-search input {
  width: 100%;
  padding: 1rem 2rem 1rem 1rem;
  background: rgba(255, 255, 255, 0.3);
  transition: all .25s ease;
}

.news-list-search input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  transition: all .25s ease;
}

.news-list-search input + button {
  fill: #fff;
  transition: all .25s ease;
}

.news-list-search input:focus {
  background: #fff;
}

.news-list-search input:focus::placeholder {
  color: #212121;
}

.news-list-search input:focus + button {
  fill: #333;
}

.news-list-search .icon {
  width: 1.5rem;
  height: 1.5rem;
}

.news-list-search button {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  .news-list-search {
    position: absolute;
    right: 3rem;
    top: 3rem;
    width: 22rem;
  }
}

/* -----------------------------------------------------------------------------

  NEWS ARTICLE

----------------------------------------------------------------------------- */
.news-article__headline {
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1;
}

.news-article__author {
  border-top: .1rem dotted #ddd;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 0;
  color: #666;
}

.news-article__author img {
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  margin: 1rem;
  display: block;
}

.news-article__author p {
  margin: 0;
}

.news-article__author a {
  color: inherit;
  text-decoration: none;
}

.news-article__author a:hover {
  text-decoration: underline;
}

.news-article__aside {
  padding: 2rem;
}

.news-article__aside h2 {
  margin: 0 0 2rem;
  font-size: 1.8rem;
  color: #666;
}

@media screen and (min-width: 480px) {
  .news-article__headline {
    font-size: 6rem;
  }
}

.news-article__feature-image-holder {
  background-image: linear-gradient(19deg, #d6556e 0%, #1f24a1 100%);
  height: 90vh;
  position: relative;
}

.news-article__feature-image-holder:after {
  display: block;
  content: '';
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
}

.news-article__feature-image-holder--with-video:after {
  display: none;
}

.news-article__content {
  background: #fff;
  min-height: 100%;
  padding: 2rem;
}

.pagebuilder-holder p,
.pagebuilder-holder li {
  font-size: 1.8rem;
  max-width: 60rem;
}

.news-article__headline {
  position: absolute;
  left: 2rem;
  top: 59%;
  transform: translateY(-50%);
  right: 2rem;
  color: #fff;
  text-transform: uppercase;
  z-index: 2;
}

.news-article__subheadline {
  position: absolute;
  left: 2rem;
  top: 71%;
  transform: translateY(-50%);
  right: 2rem;
  color: #fff;
  text-transform: uppercase;
  z-index: 2;
}

.news-article__date {
  color: #666;
  margin: 0 0 2rem;
}

.news-article__feature-video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.news-article__feature-video .plyr {
  height: 100%;
  max-width: none;
  width: auto;
  opacity: .2;
  height: 100vh;
  width: 300%;
}

.news-article__feature-video .plyr__video-embed {
  height: 100%;
}

.news-article__feature-video .plyr__controls {
  z-index: 4;
  width: 50%;
}

.news-article__feature-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.news-article__feature-image img {
  height: 100%;
  width: auto;
  max-width: none;
  opacity: .1;
}

@media screen and (min-width: 1024px) {
  .news-article__feature-image-holder {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    height: auto;
  }
  .news-article__content {
    background: #fff;
    min-height: 100%;
    padding: 30vh 5rem 0 calc(50% + 5rem);
  }
  .pagebuilder-holder p,
  .pagebuilder-holder li {
    font-size: 2.2rem;
    max-width: 60rem;
  }
  .news-article__aside {
    padding: 10rem 5rem 10rem calc(50% + 5rem);
  }
  .news-article__aside h2 {
    margin: 0 0 2rem;
    font-size: 1.8em;
    color: #666;
  }
  .news-article__feature-video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
  .news-article__feature-video .plyr {
    height: 100%;
    max-width: none;
    width: 100vw;
    opacity: .2;
  }
  .news-article__feature-video .plyr__video-embed {
    height: 100%;
  }
  .news-article__feature-video .plyr__controls {
    z-index: 4;
    width: 50%;
  }
  .news-article__headline {
    max-width: 80%;
    text-align: right;
    left: auto;
    right: 3rem;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
  .news-article__subheadline {
    max-width: 80%;
    text-align: right;
    left: auto;
    right: 3rem;
    top: 61%;
    bottom: auto;
    transform: translateY(-50%);
  }
  .news-article__date {
    position: absolute;
    right: 3rem;
    top: 3rem;
    font-size: 5em !important;
    color: #ededed;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
  }
  .news-article__author {
    margin: 0;
    max-width: 60rem;
    padding: 2rem;
    border: 1px solid #ededed;
    border-radius: 3px;
  }
  .news-article__author p {
    font-size: 1.6em !important;
    font-family: 'Roboto', sans-serif;
  }
  .news-article__back {
    font-size: 6rem;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    position: absolute;
    left: 5rem;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    color: #fff;
    text-decoration: none;
    z-index: 30;
  }
}

@media screen and (min-width: 1200px) {
  .pagebuilder-holder {
    padding-right: 5rem;
  }
  .news-article__author {
    margin: 0 0 0 5rem;
    max-width: 60rem;
    padding: 2rem;
    border: 1px solid #ededed;
    border-radius: 3px;
  }
  .news-article__author p {
    font-size: 1.6em !important;
    font-family: 'Roboto', sans-serif;
  }
}

.pagebuilder-holder .widget--text:first-child p:first-child:first-letter {
  color: #d6556e;
  float: left;
  font-size: 8.5rem;
  line-height: 6rem;
  padding-top: .4rem;
  padding-right: .8rem;
  padding-left: .3rem;
}

.news-article__map-holder {
  background: #f6f6f6;
  margin: 2rem -2rem 4rem;
  padding: 2rem;
}

.news-article__map {
  width: 100%;
  height: 60vh;
}

@media screen and (min-width: 1024px) {
  .news-article__map-holder {
    margin: 0 -5rem 4rem;
    padding: 5rem;
  }
}

.event-calendar-date {
  background: #d6556e;
  padding: 2rem;
  border-radius: .3rem;
  display: inline-block;
  text-align: center;
  width: 12rem;
  margin: 0 0 .2rem;
  color: #fff;
}

.event-calendar-date p {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: bolder;
  margin: 0;
}

.event-calendar-date .month {
  font-size: 26px;
  display: block;
  line-height: 3rem;
}

.event-calendar-date .date {
  font-size: 5rem;
  display: block;
  line-height: 4rem;
  letter-spacing: -.1em;
}

.event-calendar-date .year {
  font-size: 2rem;
  display: block;
  line-height: 2.8rem;
}

.event-calendar-date--small {
  padding: 1rem;
  width: 8rem;
  margin: 0 0 2rem;
}

.event-calendar-date--small .month {
  font-size: 1.6rem;
  display: block;
  line-height: 1.6rem;
}

.event-calendar-date--small .date {
  font-size: 3rem;
  display: block;
  line-height: 3rem;
  letter-spacing: -.1em;
}

.event-calendar-date--small .year {
  font-size: 1.2rem;
  display: block;
  line-height: 1.8rem;
}

.event-calendar-date--large {
  width: 24.4rem;
}

.block-of-events {
  margin-left: 5rem;
  margin-bottom: 3rem;
}

.event-times {
  width: 24.4rem;
  background: #d6556e;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-size: 1.6rem;
  text-align: center;
  color: #fff;
}

.news-teaser {
  position: relative;
}

.event-dates-holder {
  position: absolute;
  left: 2rem;
  top: 2rem;
}

.more-news {
  background: #ededed;
  margin: 4rem -2rem 0;
  padding: 2rem;
}

@media screen and (min-width: 1024px) {
  .more-news {
    background: #ededed;
    margin: 4rem -5rem 0;
    padding: 3rem 5rem;
  }
}

/* -----------------------------------------------------------------------------

  RELATED CONTENT
  
----------------------------------------------------------------------------- */
.related-content__link {
  display: block;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  background: #fff;
  transition: background .25s ease, color .25s ease;
  border-radius: 3px;
  overflow: hidden;
}

.related-content__link img {
  width: 25%;
  margin-right: 15px;
}

/* -----------------------------------------------------------------------------

  READING PROGRESS
  
----------------------------------------------------------------------------- */
progress.reading-progress {
  /* Positioning */
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000;
  /* Dimensions */
  width: 100%;
  height: .25em;
  /* Reset the apperance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Get rid of the default border in Firefox/Opera. */
  border: none;
  /* For Firefox/IE10+ */
  background-color: transparent;
  /* For IE10+, color of the progress bar */
  color: #FF8297;
}

progress.reading-progress::-webkit-progress-bar {
  background-color: transparent;
}

progress.reading-progress::-webkit-progress-value {
  background-color: #FF8297;
}

progress::-moz-progress-bar {
  background: #FF8297;
}

.reading-progress__container {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: 10px;
  display: block;
}

.reading-progress__bar {
  background-color: #FF8297;
  width: 50%;
  display: block;
  height: inherit;
}

video.plyr--setup {
  width: 100%;
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
  }
}

.plyr {
  position: relative;
  max-width: 100%;
  min-width: 200px;
  font-family: Avenir, "Avenir Next", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  direction: ltr;
}

.plyr,
.plyr *,
.plyr *::after,
.plyr *::before {
  box-sizing: border-box;
}

.plyr a, .plyr button, .plyr input, .plyr label {
  touch-action: manipulation;
}

.plyr:focus {
  outline: 0;
}

.plyr video,
.plyr audio {
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: inherit;
}

.plyr input[type='range'] {
  display: block;
  height: 20px;
  width: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  appearance: none;
  cursor: pointer;
  border: none;
  background: transparent;
}

.plyr input[type='range']::-webkit-slider-runnable-track {
  height: 8px;
  background: transparent;
  border: 0;
  border-radius: 4px;
  user-select: none;
}

.plyr input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -4px;
  position: relative;
  height: 16px;
  width: 16px;
  background: #fff;
  border: 2px solid transparent;
  border-radius: 100%;
  transition: background .2s ease, border .2s ease, transform .2s ease;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.plyr input[type='range']::-moz-range-track {
  height: 8px;
  background: transparent;
  border: 0;
  border-radius: 4px;
  user-select: none;
}

.plyr input[type='range']::-moz-range-thumb {
  position: relative;
  height: 16px;
  width: 16px;
  background: #fff;
  border: 2px solid transparent;
  border-radius: 100%;
  transition: background .2s ease, border .2s ease, transform .2s ease;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.plyr input[type='range']::-ms-track {
  height: 8px;
  background: transparent;
  border: 0;
  color: transparent;
}

.plyr input[type='range']::-ms-fill-upper {
  height: 8px;
  background: transparent;
  border: 0;
  border-radius: 4px;
  user-select: none;
}

.plyr input[type='range']::-ms-fill-lower {
  height: 8px;
  background: transparent;
  border: 0;
  border-radius: 4px;
  user-select: none;
  background: #d6556e;
}

.plyr input[type='range']::-ms-thumb {
  position: relative;
  height: 16px;
  width: 16px;
  background: #fff;
  border: 2px solid transparent;
  border-radius: 100%;
  transition: background .2s ease, border .2s ease, transform .2s ease;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  margin-top: 0;
}

.plyr input[type='range']::-ms-tooltip {
  display: none;
}

.plyr input[type='range']:focus {
  outline: 0;
}

.plyr input[type='range']::-moz-focus-outer {
  border: 0;
}

.plyr input[type='range'].tab-focus:focus {
  outline-offset: 3px;
}

.plyr input[type='range']:active::-webkit-slider-thumb {
  background: #d6556e;
  border-color: #fff;
  transform: scale(1.25);
}

.plyr input[type='range']:active::-moz-range-thumb {
  background: #d6556e;
  border-color: #fff;
  transform: scale(1.25);
}

.plyr input[type='range']:active::-ms-thumb {
  background: #d6556e;
  border-color: #fff;
  transform: scale(1.25);
}

.plyr--video input[type='range'].tab-focus:focus {
  outline: 1px dotted rgba(255, 255, 255, 0.5);
}

.plyr--audio input[type='range'].tab-focus:focus {
  outline: 1px dotted rgba(86, 93, 100, 0.5);
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
}

.plyr__video-wrapper {
  position: relative;
  background: #000;
  border-radius: inherit;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.plyr__video-embed {
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  overflow: hidden;
  border-radius: inherit;
}

.plyr__video-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  user-select: none;
}

.plyr__video-embed > div {
  position: relative;
  padding-bottom: 200%;
  transform: translateY(-35.95%);
}

.plyr .plyr__video-embed iframe {
  pointer-events: none;
}

.plyr video::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  transform: translateY(-60px);
  transition: transform .3s ease;
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.plyr__captions span {
  border-radius: 2px;
  padding: 3px 10px;
  background: rgba(0, 0, 0, 0.7);
  box-decoration-break: clone;
  line-height: 150%;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 24px;
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr--fullscreen-active .plyr__captions {
  font-size: 32px;
}

.plyr--hide-controls .plyr__captions {
  transform: translateY(-20px);
}

.plyr ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  display: flex;
  align-items: center;
  line-height: 1;
  text-align: center;
}

.plyr__controls > button,
.plyr__controls .plyr__progress,
.plyr__controls .plyr__time {
  margin-left: 5px;
}

.plyr__controls > button:first-child,
.plyr__controls .plyr__progress:first-child,
.plyr__controls .plyr__time:first-child {
  margin-left: 0;
}

.plyr__controls .plyr__volume {
  margin-left: 5px;
}

.plyr__controls [data-plyr="pause"] {
  margin-left: 0;
}

.plyr__controls button {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  overflow: visible;
  vertical-align: middle;
  padding: 7px;
  border: 0;
  background: transparent;
  border-radius: 3px;
  cursor: pointer;
  transition: background .3s ease, color .3s ease, opacity .3s ease;
  color: inherit;
}

.plyr__controls button svg {
  width: 18px;
  height: 18px;
  display: block;
  fill: currentColor;
}

.plyr__controls button:focus {
  outline: 0;
}

.plyr__controls .icon--exit-fullscreen,
.plyr__controls .icon--muted,
.plyr__controls .icon--captions-on {
  display: none;
}

@media (min-width: 480px) {
  .plyr__controls > button,
  .plyr__controls .plyr__progress,
  .plyr__controls .plyr__time {
    margin-left: 10px;
  }
}

.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
}

.plyr--video .plyr__controls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 50px 10px 10px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: #fff;
  transition: opacity .3s ease;
}

.plyr--video .plyr__controls button.tab-focus:focus, .plyr--video .plyr__controls button:hover {
  background: #d6556e;
  color: #fff;
}

.plyr--audio .plyr__controls {
  padding: 10px;
  border-radius: inherit;
  background: #fff;
  border: 1px solid #dbe3e8;
  color: #565D64;
}

.plyr--audio .plyr__controls button.tab-focus:focus, .plyr--audio .plyr__controls button:hover {
  background: #d6556e;
  color: #fff;
}

.plyr__play-large {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background: #d6556e;
  border: 4px solid currentColor;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  transition: all .3s ease;
}

.plyr__play-large svg {
  position: relative;
  left: 2px;
  width: 20px;
  height: 20px;
  display: block;
  fill: currentColor;
}

.plyr__play-large:focus {
  outline: 1px dotted rgba(255, 255, 255, 0.5);
}

.plyr .plyr__play-large {
  display: inline-block;
}

.plyr--audio .plyr__play-large {
  display: none;
}

.plyr--playing .plyr__play-large {
  opacity: 0;
  visibility: hidden;
}

.plyr__controls [data-plyr='pause'],
.plyr--playing .plyr__controls [data-plyr='play'] {
  display: none;
}

.plyr--playing .plyr__controls [data-plyr='pause'] {
  display: inline-block;
}

.plyr--fullscreen-active .icon--exit-fullscreen,
.plyr--muted .plyr__controls .icon--muted,
.plyr--captions-active .plyr__controls .icon--captions-on {
  display: block;
}

.plyr--fullscreen-active .icon--exit-fullscreen + svg,
.plyr--muted .plyr__controls .icon--muted + svg,
.plyr--captions-active .plyr__controls .icon--captions-on + svg {
  display: none;
}

.plyr [data-plyr='captions'],
.plyr [data-plyr='fullscreen'] {
  display: none;
}

.plyr--captions-enabled [data-plyr='captions'],
.plyr--fullscreen-enabled [data-plyr='fullscreen'] {
  display: inline-block;
}

.plyr__tooltip {
  position: absolute;
  z-index: 2;
  bottom: 100%;
  margin-bottom: 10px;
  padding: 5px 7.5px;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform .2s .1s ease, opacity .2s .1s ease;
}

.plyr__tooltip::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: -4px;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(0, 0, 0, 0.7);
  border-left: 4px solid transparent;
  z-index: 2;
}

.plyr button:hover .plyr__tooltip,
.plyr button.tab-focus:focus .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr button:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls button:first-child .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls button:first-child .plyr__tooltip::before {
  left: 16px;
}

.plyr__controls button:last-child .plyr__tooltip {
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls button:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  transform: translateX(50%);
}

.plyr__controls button:first-child:hover .plyr__tooltip,
.plyr__controls button:first-child.tab-focus:focus .plyr__tooltip,
.plyr__controls button:first-child .plyr__tooltip--visible,
.plyr__controls button:last-child:hover .plyr__tooltip,
.plyr__controls button:last-child.tab-focus:focus .plyr__tooltip,
.plyr__controls button:last-child .plyr__tooltip--visible {
  transform: translate(0, 0) scale(1);
}

.plyr__progress {
  display: none;
  position: relative;
  flex: 1;
}

.plyr__progress input[type="range"] {
  position: relative;
  z-index: 2;
}

.plyr__progress input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
}

.plyr__progress input[type="range"]::-moz-range-track {
  background: transparent;
}

.plyr__progress input[type="range"]::-ms-fill-upper {
  background: transparent;
}

.plyr__progress .plyr__tooltip {
  left: 0;
}

.plyr .plyr__progress {
  display: inline-block;
}

.plyr__progress--buffer,
.plyr__progress--played,
.plyr__volume--display {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 8px;
  margin: -4px 0 0;
  padding: 0;
  vertical-align: top;
  appearance: none;
  border: none;
  border-radius: 100px;
}

.plyr__progress--buffer::-webkit-progress-bar,
.plyr__progress--played::-webkit-progress-bar,
.plyr__volume--display::-webkit-progress-bar {
  background: transparent;
}

.plyr__progress--buffer::-webkit-progress-value,
.plyr__progress--played::-webkit-progress-value,
.plyr__volume--display::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 8px;
}

.plyr__progress--buffer::-moz-progress-bar,
.plyr__progress--played::-moz-progress-bar,
.plyr__volume--display::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 8px;
}

.plyr__progress--buffer::-ms-fill,
.plyr__progress--played::-ms-fill,
.plyr__volume--display::-ms-fill {
  border-radius: 100px;
}

.plyr__progress--played,
.plyr__volume--display {
  z-index: 1;
  color: #d6556e;
  background: transparent;
  transition: none;
}

.plyr__progress--played::-webkit-progress-value,
.plyr__volume--display::-webkit-progress-value {
  min-width: 8px;
  max-width: 99%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: none;
}

.plyr__progress--played::-moz-progress-bar,
.plyr__volume--display::-moz-progress-bar {
  min-width: 8px;
  max-width: 99%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: none;
}

.plyr__progress--played::-ms-fill,
.plyr__volume--display::-ms-fill {
  display: none;
}

.plyr__progress--buffer::-webkit-progress-value {
  transition: width .2s ease;
}

.plyr__progress--buffer::-moz-progress-bar {
  transition: width .2s ease;
}

.plyr__progress--buffer::-ms-fill {
  transition: width .2s ease;
}

.plyr--video .plyr__progress--buffer,
.plyr--video .plyr__volume--display {
  background: rgba(255, 255, 255, 0.25);
}

.plyr--video .plyr__progress--buffer {
  color: rgba(255, 255, 255, 0.25);
}

.plyr--audio .plyr__progress--buffer,
.plyr--audio .plyr__volume--display {
  background: rgba(198, 214, 219, 0.67);
}

.plyr--audio .plyr__progress--buffer {
  color: rgba(198, 214, 219, 0.67);
}

.plyr--loading .plyr__progress--buffer {
  animation: plyr-progress 1s linear infinite;
  background-size: 25px 25px;
  background-repeat: repeat-x;
  background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.15) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.15) 75%, transparent 75%, transparent);
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress--buffer {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--audio.plyr--loading .plyr__progress--buffer {
  background-color: rgba(198, 214, 219, 0.67);
}

.plyr__time {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}

.plyr__time + .plyr__time {
  display: none;
}

@media (min-width: 768px) {
  .plyr__time + .plyr__time {
    display: inline-block;
  }
}

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px;
}

.plyr__volume {
  display: none;
}

.plyr .plyr__volume {
  flex: 1;
  position: relative;
}

.plyr .plyr__volume input[type="range"] {
  position: relative;
  z-index: 2;
}

@media (min-width: 480px) {
  .plyr .plyr__volume {
    display: block;
    max-width: 60px;
  }
}

@media (min-width: 768px) {
  .plyr .plyr__volume {
    max-width: 100px;
  }
}

.plyr--is-ios .plyr__volume,
.plyr--is-ios [data-plyr='mute'] {
  display: none !important;
}

.plyr--fullscreen-active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 10000000;
  background: #000;
  border-radius: 0 !important;
}

.plyr--fullscreen-active video {
  height: 100%;
}

.plyr--fullscreen-active .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr--fullscreen-active .plyr__controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.plyr--fullscreen-active.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.person {
  padding: 0;
  color: #121212;
  background: #fff;
  margin: 0 2rem 2rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
  padding: 2rem;
}

.person-image-holder {
  padding-bottom: 100%;
  display: block;
  background: #ededed;
  position: relative;
}

.person-image-holder img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.default-image-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .people-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 2rem;
    justify-content: center;
  }
  .person {
    width: calc(33.3333% - 4rem);
    margin: 0 2rem 4rem;
  }
}

.case-study {
  padding: 0;
  color: #121212;
  background: #fff;
  margin: 0 2rem 2rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
  padding: 2rem;
}

.case-study-image-holder {
  padding-bottom: 100%;
  display: block;
  background: #ededed;
  position: relative;
}

.case-study-image-holder img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.default-image-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .case-study-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 2rem;
    justify-content: center;
  }
  .case-study {
    width: calc(33.3333% - 4rem);
    margin: 0 2rem 4rem;
  }
}

/* -----------------------------------------------------------------------------

  STAGGERED ENTRY
  
----------------------------------------------------------------------------- */
.js .not-visible {
  transform: translateY(50px);
}

.come-in {
  transform: translateY(50px);
  animation: come-in .5s ease forwards;
}

@media only screen and (min-width: 480px) {
  .js .not-visible {
    transform: translateY(150px);
  }
  .come-in {
    transform: translateY(150px);
    animation: come-in .8s ease forwards;
  }
  .come-in:nth-child(2n+1) {
    animation-duration: 0.6s;
  }
}

@media only screen and (min-width: 1024px) {
  .come-in:nth-child(3n+1) {
    animation-duration: 0.6s;
  }
  .come-in:nth-child(3n+2) {
    animation-duration: 0.8s;
  }
  .come-in:nth-child(3n+3) {
    animation-duration: 1s;
  }
}

@media only screen and (min-width: 1200px) {
  .come-in:nth-child(4n+1) {
    animation-duration: .6s;
  }
  .come-in:nth-child(4n+2) {
    animation-duration: .8s;
  }
  .come-in:nth-child(4n+3) {
    animation-duration: 1s;
  }
  .come-in:nth-child(4n) {
    animation-duration: 1.2s;
  }
}

.js .already-visible {
  transform: translateY(0);
  animation: none;
}

@keyframes come-in {
  to {
    transform: translateY(0);
  }
}

/* -----------------------------------------------------------------------------

  WIDGETS
  
----------------------------------------------------------------------------- */
.widget {
  margin: 0 0 20px;
}

@media screen and (min-width: 1024px) {
  .widget {
    margin: 0 0 40px;
  }
}

.widget--video {
  border-radius: 3px;
  overflow: hidden;
}

.widget--snippet {
  background: #ededed;
  padding: 20px;
}

.widget--snippet h2 {
  margin: 0 0 10px;
}

.widget--snippet p:last-of-type {
  margin: 30px 0 0;
}

@media screen and (min-width: 1024px) {
  .widget--snippet {
    background: #ededed;
    padding: 40px;
    margin: 0 0 40px 50px;
    border-radius: 3px;
  }
  .widget--snippet h2 {
    margin: 0 0 10px;
  }
  .widget--snippet p:last-of-type {
    margin: 30px 0 0;
  }
}

@media screen and (min-width: 1200px) {
  .widget--text p {
    margin-left: 50px;
  }
}

.widget--section-title {
  margin: 50px 0 40px;
}

.widget--section-title h2 {
  margin: 0;
  color: #d6556e;
}

.widget--image img {
  width: 100%;
  height: auto;
  border-radius: 3px;
}

.widget--quote {
  background-image: linear-gradient(19deg, #d6556e 0%, #1f24a1 100%);
  background: #f6f6f6;
  color: #d6556e;
  padding: 20px;
  border-radius: 3px;
  max-width: 600px;
}

.widget--quote blockquote {
  padding: 0;
  margin: 0;
}

.widget--quote blockquote p {
  line-height: 1.2;
  font-size: 2.4em;
}

.widget--quote footer {
  border-top: 1px dotted #d6556e;
  padding: 10px 0 0;
  font-size: 1.4em;
  font-family: 'Roboto', sans-serif;
}

@media screen and (min-width: 1024px) {
  .widget--quote {
    padding: 40px;
    margin: 0 0 40px 50px;
  }
  .widget--quote footer {
    margin: 20px 0 0;
    padding: 20px 0 0;
  }
  .widget--image + .widget--quote {
    margin: -100px 0 40px 50px;
    position: relative;
    z-index: 2;
  }
  .widget--section-title {
    margin-left: 50px;
  }
}

/*! Demo */
body {
  background: #ededed;
}

/* -----------------------------------------------------------------------------

  Venues modules
  
----------------------------------------------------------------------------- */
#list-venues.section {
  padding: 50px 50px !important;
}

#debugThing {
  display: none;
}

#list-venues .standard-form-control {
  width: 272px;
}

#list-venues .icon-form-control {
  padding-left: 40px;
}

#list-venues .search-venues {
  icon: pointer;
}

#list-venues .icon-form-control .current-location-icon {
  left: 0;
  right: auto;
}

#list-venues .icon-form-control button {
  background-color: transparent;
  cursor: pointer;
}

#list-venues .icon-form-control .current-location-icon svg {
  width: 30px;
  height: 30px;
}

#list-venues .standard-form-control:focus {
  box-shadow: none !important;
}

#list-venues .icon-form-control {
  background-color: white;
}

#view-venue .address,
#view-venue .contact {
  padding-left: 0;
}

#view-venue .venue-fullname {
  margin: 0;
  padding: 28px 0;
}

#view-venue p {
  white-space: pre-line;
  margin: 0;
}

#view-venue h3 {
  margin-bottom: 0;
}

#view-venue .map {
  padding-left: 0;
  padding-right: 35px;
}

#view-venue h1 {
  padding-top: 0;
}

#view-venue .opening-time h3 {
  padding-bottom: 20px;
}

#view-venue .back-to-list {
  padding-left: 30px;
}

#view-venue .back-to-list p {
  display: -webkit-inline-box;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

#map {
  height: 100%;
}

/* Optional: Makes the sample page fill the window. */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#description {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  margin: 10px 10px 0 0;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-family: Roboto;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  margin-left: 0;
  padding: 6px 40px 6px 40px;
  text-overflow: ellipsis;
  width: 272px;
  height: 26px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

#target {
  width: 345px;
}

/* venue results */
#list-venues .venues-results {
  display: none;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

#map {
  height: 100%;
}

/* list venues with directions */
/* venue results */
#list-venues.section {
  padding: 50px 50px !important;
}

#debugThing {
  display: none;
}

#list-venues .icon-form-control {
  padding-left: 40px;
}

#list-venues .search-venues {
  icon: pointer;
}

#list-venues .icon-form-control .current-location-icon {
  left: 0;
  right: auto;
}

#list-venues .icon-form-control button {
  background-color: transparent;
  cursor: pointer;
}

#list-venues .icon-form-control .current-location-icon svg {
  width: 30px;
  height: 30px;
}

#list-venues .standard-form-control:focus {
  box-shadow: none !important;
}

#list-venues .google-map-container {
  position: relative;
}

#list-venues .search-venue-input-container {
  position: absolute;
  z-index: 10;
}

#list-venues .googleMap {
  position: absolute;
}

#list-venues .icon-form-control {
  background-color: white;
}

/* Input search location */
.pac-container {
  width: 353px !important;
  /*border-radius: 5px;*/
  left: 50 !important;
  box-shadow: none;
  border: 1px solid #ccc;
}

#list-venues .standard-form-control {
  width: 272px;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}

/* list venues results */
ul.transport-types {
  list-style: none;
  width: 100%;
}

ul.transport-types li {
  display: inline-block;
}

ul.transport-types li label {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 5px;
  width: auto;
  margin: 0 5px 0 0;
  cursor: pointer;
}

ul.transport-types li input[type="radio"]:checked + label {
  border: 1px solid red;
}

ul.transport-types li:last-child {
  margin: 0;
}

#view-venue ul.transit-transport-type-list li label {
  border: 1px solid #AAB2BD;
  border-radius: 5px;
  color: #AAB2BD;
}

#view-venue ul.transit-transport-type-list li input[type="radio"]:checked + label {
  border: 1px solid #E9573F;
  color: #E9573F;
}

#view-venue ul.transport-types {
  display: none;
}

#view-venue .show-directions-btn {
  padding-bottom: 25px;
}

#view-venue #map {
  padding: 0;
  margin: 35px 0 35px 35px;
  height: 600px;
}

#view-venue .list-transit-transports {
  padding: 20px 0 20px 4px;
}

/* -----------------------------------------------------------------------------

  Venue Locator
  
----------------------------------------------------------------------------- */
/*
#venue-locator #map { height: calc(100vh - 70px); }

//#venue-locator .none{ display: none; }

#venue-locator .icon-form-control{ padding-left: 40px; }


#venue-locator .search-venues{ icon: pointer; }


#venue-locator .icon-form-control .current-location-icon{
    left: 0;
    right: auto;   
}

#venue-locator .icon-form-control button{ background-color: transparent; cursor: pointer; }

#venue-locator .icon-form-control .current-location-icon svg{ width: 30px; height: 30px; }

#venue-locator .standard-form-control:focus{ box-shadow: none !important; }

#venue-locator .icon-form-control{ background-color: white; }

#venue-locator .btn-back-to-search-results{ padding: 12px; }

#venue-locator .standard-form-control{ width: 272px; }

#venue-locator.map-holder{ position: relative; overflow: hidden; }

#venue-locator .venue-list-results-item:hover{ background-color: #F5F7FA; cursor: pointer; }

#venue-locator .venue-list-results-item{ margin: 0; border-top: 1px solid #EEEEEE;}

#venue-locator .venue-list-results-item,
#venue-locator .venues-directions-form-1, 
#venue-locator .venues-directions-form-2, 
#venue-locator .venue-direction-panel-container{ padding: 14px 12px; }

.map-sidebar{ 
    position: absolute; 
    left:-100%; 
    bottom: 0; 
    top:0; 
    background: #fff; 
    width: 25%;
    min-width: 320px;
    padding: 80px 0;
    z-index: 100;
    transition: left .5s ease;
}

.search-venue-input-container { position: absolute; left: 10px; top: 10px; background: #fff; z-index: 200; 
    p { margin: 0; }
}

.show-results .venues-results, 
.show-directions .venues-directions-form { left: 0; }

.venue-direction-panel-container,
.show-directions-instructions .venues-directions-form-2 { display: none; }
.show-directions-instructions .venue-direction-panel-container { display: block; }

.margin-map{ margin-left: 436px; }*/
/* venue transit tansport types*/
ul.transport-types {
  list-style: none;
  width: 100%;
  padding-left: 0;
}

ul.transport-types li {
  display: inline-block;
}

ul.transport-types li label {
  border: 1px solid #656D78;
  border-radius: 5px;
  padding: 6px 8px;
  width: auto;
  margin: 0 5px 0 0;
  cursor: pointer;
  color: #656D78;
}

ul.transport-types li input[type="radio"]:checked + label {
  border: 1px solid #4A89DC;
  color: #4A89DC;
}

ul.transport-types li:last-child {
  margin: 0;
}

/*
#debugThing { display: none; }*/
/* -----------------------------------------------------------------------------

                Directions styles
  
----------------------------------------------------------------------------- */
/* Directions panel */
.adp-summary,
.adp-legal,
.adp-text,
.adp-marker,
.adp-placemark,
.warnbox-content,
.adp-agencies,
.adp-warnbox {
  display: none;
}

.adp-details {
  padding-left: 45px;
}

.adp-directions {
  width: 100%;
}

td.adp-substep {
  padding: 24px 20px;
}

.adp-directions tr {
  height: 70px;
  padding: 14px 12px;
  border-top: 1px solid #EEEEEE;
  line-height: 1.5;
  cursor: pointer;
}

td.adp-substep > div > div {
  display: none;
}

td.adp-substep > :nth-child(3),
td.adp-substep > :nth-child(1) {
  padding: 5px 45px;
  display: none;
}

td.adp-substep > :nth-child(2) {
  margin-left: 0 !important;
}

td.adp-substep img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.venue-direction-summary-locations {
  text-align: center;
}

.venue-direction-summary-locations-arrow {
  width: 30px;
  height: 30px;
}

/* -----------------------------------------------------------------------------

  PASSWORD INPUT METER

----------------------------------------------------------------------------- */
[v-cloak] {
  display: none;
}

.Password {
  max-width: 400px;
  margin: 0 auto;
}

.Password__group {
  position: relative;
}

.Password__strength-meter {
  position: relative;
  height: 3px;
  background: #DDD;
  margin: 10px auto 20px;
  border-radius: 3px;
}

.Password__strength-meter:before, .Password__strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #FFF;
  border-style: solid;
  border-width: 0 5px 0 5px;
  position: absolute;
  width: 80px;
  z-index: 10;
}

.Password__strength-meter:before {
  left: 70px;
}

.Password__strength-meter:after {
  right: 70px;
}

.Password__strength-meter--fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.Password__strength-meter--fill[data-score='0'] {
  background: darkred;
  width: 20%;
}

.Password__strength-meter--fill[data-score='1'] {
  background: orangered;
  width: 40%;
}

.Password__strength-meter--fill[data-score='2'] {
  background: orange;
  width: 60%;
}

.Password__strength-meter--fill[data-score='3'] {
  background: yellowgreen;
  width: 80%;
}

.Password__strength-meter--fill[data-score='4'] {
  background: green;
  width: 100%;
}

.Password__field {
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 13px;
  width: 100%;
}

.Password__badge {
  float: right;
  position: relative;
  bottom: 33px;
  right: 10px;
  color: white;
  padding: 2px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 1.1;
}

.Password__badge--error {
  background: red;
}

.Password__badge--success {
  background: #1bbf1b;
}
