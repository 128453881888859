/* -----------------------------------------------------------------------------

  NEWS LIST
  
----------------------------------------------------------------------------- */

$news-teaser-border-colour: #ddd;

.page-heading { padding: 6rem 2rem 4rem; text-align: center; background: rgb(214, 85, 110); color: #fff; margin: 0 0 2rem;
    h1 { font-size: 5rem; text-transform: uppercase; color: #fff; }
}

@media screen and (min-width: $md-width) {
    .page-heading { padding: 15rem 6rem 5rem; text-align: center; color: #fff; margin: 0 0 5rem;
        h1 { font-size: 7.2rem; text-transform: uppercase; }
    }
}

.news-teasers { padding: 0 0 10rem; margin: 0 auto; }
.news-teaser-list { display: flex; flex-wrap: wrap; padding: 0 2rem; justify-content: center; }

@media screen and (max-width: 767px) {
 .news-teasers { padding: 10rem 0 5rem; margin: 0 auto; max-width: 40rem; }
}

.news-teaser { padding: 0; color: #121212; background: #fff; display: flex; margin: 0 0 4rem; box-shadow: 1px 1px 1px rgba(0,0,0,.1); border-radius: 3px; overflow: hidden; }
	
.news-teaser__image-link { display: block; margin: 0; position: relative; overflow: hidden; width: 50%;
		img { display: block; width: 100%; }
	}
.news-teaser__hover-content { position: absolute; left: -100%; top: 0; width: 100%; height: 100%; background: rgba(121, 220, 232, .9); display: block; }
.news-teaser__read-more { position: absolute; display: block; padding: 2rem; background: #fff; border-radius: 50%; left: 50%; top: 50%; margin: -32px 0 0 -32px; }
.news-teaser__headline { font-size: 3em; margin: 0 0 1rem; font-weight: 700; 
        a { color: #121212; text-decoration: none; }
    }
.news-teaser__article-meta { font-size: 1.2em; color: #121212; position: relative; font-family: 'Raleway', sans-serif;
		a { color: #333; text-decoration: none; 
			&:hover { text-decoration: underline; }
		}		
	}
.news-teaser__text { width: 50%; padding: 2rem;	}
@media screen and (min-width: $sm-width) {
    .news-teaser__text { display: flex; flex-direction: column; }    
}
.news-teaser__actions { text-align: right; padding: 1rem 0 0; font-size: 1.8em; margin: 0; }
.news-teaser__max-height { position: relative;
    &:after { content: ''; display: block; background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); height: 3rem; position: absolute; left: 0; right: 0; bottom: 0; z-index: 100; }
}
@media screen and (min-width: $sm-width) {
    .news-teaser__max-height { max-height: 211px; overflow: hidden; flex-grow: 1; }    
}
    
.news-teaser--sticky { position: relative; 
    &:after { content: 'Featured'; display: block; height: 3rem; left: -2px; top: 1rem; position: absolute; background: $primary-colour; line-height: 3rem; color: #fff; text-transform: uppercase; padding: 0 1rem; font-size: 1.2em; box-shadow: 0 0 3px rgba(0,0,0,.4); }
}
.news-teaser__categories { 
    a { font-size: 1.2rem; background: $primary-colour; padding: .4rem .8rem; border-radius: .2rem; color: #fff; position: relative; font-family: 'Raleway', sans-serif; text-decoration: none; }
}


@media screen and (max-width: $sm-width-under) {
 .news-teaser { width: 100%; margin: 0 0 2rem; flex-direction: column-reverse; }
 .news-teaser__text,
 .news-teaser__image-link { width: 100%; }
}

@media screen and (min-width: $xl-width) {
.news-teasers { max-width: none; }
 .news-teaser:not(.news-teaser--full-width) { width: calc(50% - 4rem); max-width: 80rem; margin: 0 2rem 4rem; }
    
 
}

.news-teaser-list--single-row {
	.news-teaser { transform: translateY(0) !important; }
}

.news-teaser__teaser { flex-grow: 1;}

.news-list-search {  position: relative; width: 100%; border-radius: .5rem; overflow: hidden;
    input { width: 100%; padding: 1rem 2rem 1rem 1rem; background: rgba(255,255,255,.3); transition: all .25s ease;
        &::placeholder { color: rgba(255,255,255,.7); transition: all .25s ease; }
        + button { fill: #fff; transition: all .25s ease; }
    }
    input:focus { background: #fff; 
        &::placeholder { color: #212121; }
        + button { fill: #333; }
    }
    .icon { width: 1.5rem; height: 1.5rem; }
    button { background: none; border: none; position: absolute; right: 0; top: 50%; transform: translateY(-50%); }
}


@media screen and (min-width: $md-width) {
    .news-list-search { position: absolute; right: 3rem; top: 3rem; width: 22rem; }  
}