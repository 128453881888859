/* -----------------------------------------------------------------------------

  NEWS ARTICLE

----------------------------------------------------------------------------- */

.news-article {}

.news-article__headline { font-size: 3.4rem; font-weight: 700; line-height: 1; }

.news-article__author { border-top: .1rem dotted #ddd; display: flex; justify-content: flex-start; align-items: center; margin: 2rem 0; color: #666;
		img { border-radius: 50%; height: 5rem; width: 5rem; margin: 1rem; display: block; }
		p { margin: 0; }
		a { color: inherit; text-decoration: none;
			&:hover { text-decoration: underline; }
		}
	}
.news-article__aside { padding: 2rem;
		h2 { margin: 0 0 2rem; font-size: 1.8rem; color: #666; }
	}

@media screen and (min-width: $sm-width) {
    .news-article__headline { font-size: 6rem; }  
}

.news-article__feature-image-holder { background-image: linear-gradient(19deg, rgb(214, 85, 110) 0%, rgb(31, 36, 161) 100%); height: 90vh; position: relative;
    &:after { display: block; content: ''; background: rgba(0,0,0,.2); position: absolute; left: 0; right: 0; top: 0; height: 100%; }
}
.news-article__feature-image-holder--with-video {
    &:after { display: none; }
}

.news-article__content { background: #fff; min-height: 100%; padding: 2rem; }
.pagebuilder-holder {
      p,
      li { font-size: 1.8rem; max-width: 60rem; }
    }

.news-article__headline { position: absolute; left: 2rem; top: 59%; transform: translateY(-50%); right: 2rem; color: #fff; text-transform: uppercase; z-index: 2; }
.news-article__subheadline { position: absolute; left: 2rem; top: 71%; transform: translateY(-50%); right: 2rem; color: #fff; text-transform: uppercase; z-index: 2; }

.news-article__date { color: #666; margin: 0 0 2rem;}

.news-article__feature-video { position: absolute; left: 0; right: 0; top: 0; bottom: 0; overflow: hidden;
.plyr { height: 100%; max-width: none; width: auto; opacity: .2; height: 100vh;  width: 300% }
.plyr__video-embed { height: 100%; }
.plyr__controls { z-index: 4; width: 50%; }
}

.news-article__feature-image { position: absolute; left: 0; right: 0; top: 0; bottom: 0; overflow: hidden;
    img { height: 100%; width: auto; max-width: none; opacity: .1; }
}

@media screen and (min-width: 1024px) {



    .news-article__feature-image-holder {
		position: fixed; left: 0; top: 0; bottom: 0; width: 50%; height: auto;
	}
    .news-article__content { background: #fff; min-height: 100%; padding: 30vh 5rem 0 calc(50% + 5rem);
        
    }
    .pagebuilder-holder {
      p,
      li { font-size: 2.2rem; max-width: 60rem; }
    }
    .news-article__aside { padding: 10rem 5rem 10rem calc(50% + 5rem);
		h2 { margin: 0 0 2rem; font-size: 1.8em; color: #666; }
	}

    .news-article__feature-video { position: absolute; left: 0; right: 0; top: 0; bottom: 0; overflow: hidden;
    .plyr { height: 100%; max-width: none; width: 100vw; opacity: .2; }
    .plyr__video-embed { height: 100%; }
    .plyr__controls { z-index: 4; width: 50%; }
    }

    .news-article__headline { max-width: 80%; text-align: right; left: auto; right: 3rem; top: 50%; bottom: auto; transform: translateY(-50%); }
		.news-article__subheadline { max-width: 80%; text-align: right; left: auto; right: 3rem; top: 61%; bottom: auto; transform: translateY(-50%); }

    .news-article__date { position: absolute; right: 3rem; top: 3rem; font-size: 5em !important; color: #ededed; font-family: 'Roboto', sans-serif; text-transform: uppercase; }

    .news-article__author { margin: 0 ; max-width: 60rem; padding: 2rem; border: 1px solid #ededed; border-radius: 3px;
        p { font-size: 1.6em !important; font-family: 'Roboto', sans-serif; }
    }

    .news-article__back { font-size: 6rem; color: #fff; font-family: 'Raleway', sans-serif; font-weight: 300; position: absolute; left: 5rem; top: 50%; transform: translateY(-50%); display: block; color: #fff; text-decoration: none; z-index: 30; }

}

@media screen and (min-width: $xl-width) {
    .pagebuilder-holder { padding-right: 5rem; }    
    .news-article__author { margin: 0 0 0 5rem; max-width: 60rem; padding: 2rem; border: 1px solid #ededed; border-radius: 3px;
        p { font-size: 1.6em !important; font-family: 'Roboto', sans-serif; }
    }
}
.pagebuilder-holder {
    .widget--text:first-child {
        p:first-child:first-letter { color: rgb(214, 85, 110); float: left; font-size: 8.5rem; line-height: 6rem; padding-top: .4rem; padding-right: .8rem; padding-left: .3rem; }
    }
}

.news-article__map-holder { background: #f6f6f6; margin: 2rem -2rem 4rem; padding: 2rem; }
.news-article__map { width: 100%; height: 60vh; }

@media screen and (min-width: 1024px) {
.news-article__map-holder { margin: 0 -5rem 4rem; padding: 5rem; }
}

.event-calendar-date { background: #d6556e; padding: 2rem; border-radius: .3rem; display: inline-block; text-align: center; width: 12rem; margin: 0 0 .2rem; color: #fff;
    p { font-family: 'Roboto', sans-serif; text-transform: uppercase; font-weight: bolder; margin: 0; }
    .month { font-size: 26px; display: block; line-height: 3rem; }
    .date { font-size: 5rem; display: block; line-height: 4rem; letter-spacing: -.1em; }
    .year { font-size: 2rem; display: block; line-height: 2.8rem; }
}

 .event-calendar-date--small { padding: 1rem; width: 8rem; margin: 0 0 2rem;
     .month { font-size: 1.6rem; display: block; line-height: 1.6rem; }
    .date { font-size: 3rem; display: block; line-height: 3rem; letter-spacing: -.1em; }
    .year { font-size: 1.2rem; display: block; line-height: 1.8rem; }
 }

 .event-calendar-date--large{
	 	width: 24.4rem;
 }

.block-of-events { margin-left: 5rem; margin-bottom: 3rem; }
.event-times { width: 24.4rem; background: #d6556e; padding: 2rem; font-family: 'Roboto', sans-serif; text-transform: uppercase; font-size: 1.6rem; text-align: center; color: #fff; }

.news-teaser { position: relative; }
.event-dates-holder { position: absolute; left: 2rem; top: 2rem; }


.more-news { background: #ededed; margin: 4rem -2rem 0; padding: 2rem; }

@media screen and (min-width: 1024px) {
  .more-news { background: #ededed; margin: 4rem -5rem 0; padding: 3rem 5rem; }  
}