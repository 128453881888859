/* -----------------------------------------------------------------------------

  OVERLAYS
  
----------------------------------------------------------------------------- */

.overlay-bg { background: rgba(0,0,0,.5); position: fixed; left: 0; top: 0; width: 100%; height: 100%; visibility: hidden; opacity: 0; transition:visibility 0s linear 0.25s,opacity 0.25s linear; z-index: 1999; }
.overlay-bg.overlay-bg--visible { visibility: visible; opacity: 1; transition-delay:0s; }

.overlay { visibility: hidden; opacity: 0; position: absolute; left: 50%; top: 50%; width: 560px; height: 500px; background: #fff; transform: scale(.5,.5) translate(-50%, -50%); transition:visibility 0s linear 0.25s,opacity 0.25s linear,transform 0.25s linear; z-index: 2000; transform-origin: left top;

    &.overlay--visible { visibility: visible; opacity: 1; transform: scale(1,1) translate(-50%, -50%); box-shadow: 5px 5px 5px rgba(0,0,0,.3); transition-delay:0s; }

}

.overlay--massive { width: 300px; height: auto; margin: -500px 0 0 -150px; }
.overlay--modal { width: 320px; height: 230px; margin: -115px 0 0 -160px; text-align: center; }
.overlay--modal__actions { padding: 20px 0;
    li { display: inline-block; margin: 0 20px; }   
}
.overlay__content-holder--modal { display: flex; height: 100%; flex-direction: column; }
.overlay__modal-text { flex-grow: 1; }


.overlay.overlay--full { width: 100%; height: 100%; left: 0; top: 0; margin: 0; position: fixed; }
.overlay--fixed { position: fixed;
    &.overlay--tall { top: 20px; bottom: 20px; height: auto; margin-top: 0; }
}

.overlay__close { position: absolute; right: 0; top: 0; cursor: pointer; padding: 15px; z-index: 2000; }

.overlay__content-holder { overflow: auto; -webkit-overflow-scrolling: touch; height: 100%; padding: 50px 20px 20px; }

@media screen and (min-width: $md-width) {
    .main-content { transition: filter .25s ease; }
    .overlay-showing {
        .main-content { filter: grayscale(80%); } 
    }
}

@media screen and (min-width: $md-width) {
.overlay--video { max-width: 1024px; height: auto; width: 100%; }
}