/* -----------------------------------------------------------------------------

  READING PROGRESS
  
----------------------------------------------------------------------------- */

progress.reading-progress {
  /* Positioning */
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000;

  /* Dimensions */
  width: 100%;
  height: .25em;

  /* Reset the apperance */  
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;

  /* Get rid of the default border in Firefox/Opera. */
  border: none;

  /* For Firefox/IE10+ */
  background-color: transparent;

  /* For IE10+, color of the progress bar */
  color: $colour4;
}

progress.reading-progress::-webkit-progress-bar {
  background-color: transparent;
}

progress.reading-progress::-webkit-progress-value {
  background-color: $colour4;
}
progress::-moz-progress-bar { 
    background: $colour4;  
}

.reading-progress__container {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: 10px;
  display: block;
}

.reading-progress__bar {
  background-color: $colour4;
  width: 50%;
  display: block;
  height: inherit;
}
