/* -----------------------------------------------------------------------------

  HELPERS
  
----------------------------------------------------------------------------- */

img { max-width: 100%; height: auto; }
html { box-sizing: border-box; }
*,
*:before,
*:after { box-sizing: inherit; }

.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

.clearfix:before, .clearfix:after { content: " "; display: table; }
.clearfix:after,
.clear-both { clear: both; }

.hide { display: none; }

.text-align-right { text-align: right; }

.preload * { -webkit-transition: none !important; -moz-transition: none !important; -ms-transition: none !important; -o-transition: none !important; }

.relative { position: relative; }
.reset-list { list-style: none; margin: 0; padding: 0; }
.reset-link { color: inherit; text-decoration: none; }