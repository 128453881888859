/* -----------------------------------------------------------------------------

  Venues modules
  
----------------------------------------------------------------------------- */
#list-venues.section{ padding: 50px 50px !important; }

#debugThing{ display: none; }

#list-venues .standard-form-control{ width: 272px;}

#list-venues .icon-form-control{ padding-left: 40px; }

#list-venues .search-venues{ icon: pointer; }

#list-venues .icon-form-control .current-location-icon{
    left: 0;
    right: auto;   
}

#list-venues .icon-form-control button{ background-color: transparent; cursor: pointer; }

#list-venues .icon-form-control .current-location-icon svg{ width: 30px; height: 30px; }

#list-venues .standard-form-control:focus{ box-shadow: none !important; }

//#list-venues .google-map-container{ position: relative; //

//#list-venues .search-venue-input-container{ position: absolute; z-index: 10; }

//#list-venues .googleMap{ position: absolute; }

#list-venues .icon-form-control{ background-color: white; }

#view-venue .address, 
#view-venue .contact{ padding-left: 0; }

#view-venue .venue-fullname{ margin: 0; padding: 28px 0; }

#view-venue p{ white-space: pre-line; margin: 0; }

#view-venue h3{ margin-bottom: 0; }

#view-venue .map{ padding-left: 0; padding-right: 35px; }

#view-venue h1{ padding-top: 0; }

#view-venue .opening-time h3{ padding-bottom: 20px; }

#view-venue .back-to-list{ padding-left: 30px; }

#view-venue .back-to-list p{ display: -webkit-inline-box; }

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

#map {
    height: 100%;
}
/* Optional: Makes the sample page fill the window. */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
#description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    margin-left: 0;
    padding: 6px 40px 6px 40px;
    text-overflow: ellipsis;
    width: 272px;
    height: 26px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}
#target {
    width: 345px;
}

/* venue results */
#list-venues .venues-results{ display: none; }

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

#map {
    height: 100%;
}
/* list venues with directions */

/* venue results */
#list-venues.section{ padding: 50px 50px !important; }

#debugThing{ display: none; }

#list-venues .icon-form-control{ padding-left: 40px; }

#list-venues .search-venues{ icon: pointer; }

#list-venues .icon-form-control .current-location-icon{
    left: 0;
    right: auto;   
}

#list-venues .icon-form-control button{ background-color: transparent; cursor: pointer; }

#list-venues .icon-form-control .current-location-icon svg{ width: 30px; height: 30px; }

#list-venues .standard-form-control:focus{ box-shadow: none !important; }

#list-venues .google-map-container{ position: relative; }

#list-venues .search-venue-input-container{ position: absolute; z-index: 10; }

#list-venues .googleMap{ position: absolute; }

#list-venues .icon-form-control{ background-color: white; }

/* Input search location */
.pac-container{
    width: 353px !important;
    /*border-radius: 5px;*/
    left: 50 !important;
    box-shadow: none;
    border: 1px solid #ccc;
}

#list-venues .standard-form-control{ width: 272px; }

.pac-container:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
    background-image: none !important;
    height: 0px;
}

/* list venues results */

ul.transport-types {
    list-style: none;
    width: 100%;
    & li {
        display: inline-block;
        
        &  label {
            border: 1px solid black;
            border-radius: 5px;
            padding: 10px 5px;
            width:auto;
            margin: 0 5px 0 0;
            cursor: pointer;
        }
        & input[type="radio"]:checked + label {
            border: 1px solid red;
          } 
    }
    & li:last-child { margin: 0; }
}

#view-venue ul.transit-transport-type-list{
    & li {
        &  label {
            border: 1px solid #AAB2BD;
            border-radius: 5px;
            color: #AAB2BD;
        }
        & input[type="radio"]:checked + label {
            border: 1px solid #E9573F;
            color: #E9573F;
        } 
    }
}

#view-venue ul.transport-types{ display: none; }

#view-venue .show-directions-btn{ padding-bottom: 25px; }

#view-venue #map{ padding: 0; margin: 35px 0 35px 35px; height: 600px; }

#view-venue .list-transit-transports{ padding: 20px 0 20px 4px; }