/* -----------------------------------------------------------------------------

                Directions styles
  
----------------------------------------------------------------------------- */
/* Directions panel */
.adp-summary, 
.adp-legal,
.adp-text,
.adp-marker,
.adp-placemark,
.warnbox-content,
.adp-agencies,
.adp-warnbox{ display: none; }

.adp-details{ padding-left: 45px; }

.adp-directions{ width: 100%; }

 td.adp-substep{ padding: 24px 20px;}

.adp-directions tr{ 
    height: 70px;
    padding: 14px 12px;
    border-top: 1px solid #EEEEEE;
    line-height: 1.5;
    cursor: pointer;
}


td.adp-substep > div > div { display: none; }

td.adp-substep > :nth-child(3), 
td.adp-substep > :nth-child(1) { padding: 5px 45px; display: none;}

td.adp-substep > :nth-child(2) { margin-left: 0 !important; }

td.adp-substep img{ width: 20px; height: 20px; margin-right: 20px; }

.venue-direction-summary-locations{ text-align: center; }

.venue-direction-summary-locations-arrow{ width: 30px; height: 30px; }