/* -----------------------------------------------------------------------------

  Venue Locator
  
----------------------------------------------------------------------------- */
/*
#venue-locator #map { height: calc(100vh - 70px); }

//#venue-locator .none{ display: none; }

#venue-locator .icon-form-control{ padding-left: 40px; }


#venue-locator .search-venues{ icon: pointer; }


#venue-locator .icon-form-control .current-location-icon{
    left: 0;
    right: auto;   
}

#venue-locator .icon-form-control button{ background-color: transparent; cursor: pointer; }

#venue-locator .icon-form-control .current-location-icon svg{ width: 30px; height: 30px; }

#venue-locator .standard-form-control:focus{ box-shadow: none !important; }

#venue-locator .icon-form-control{ background-color: white; }

#venue-locator .btn-back-to-search-results{ padding: 12px; }

#venue-locator .standard-form-control{ width: 272px; }

#venue-locator.map-holder{ position: relative; overflow: hidden; }

#venue-locator .venue-list-results-item:hover{ background-color: #F5F7FA; cursor: pointer; }

#venue-locator .venue-list-results-item{ margin: 0; border-top: 1px solid #EEEEEE;}

#venue-locator .venue-list-results-item,
#venue-locator .venues-directions-form-1, 
#venue-locator .venues-directions-form-2, 
#venue-locator .venue-direction-panel-container{ padding: 14px 12px; }

.map-sidebar{ 
    position: absolute; 
    left:-100%; 
    bottom: 0; 
    top:0; 
    background: #fff; 
    width: 25%;
    min-width: 320px;
    padding: 80px 0;
    z-index: 100;
    transition: left .5s ease;
}

.search-venue-input-container { position: absolute; left: 10px; top: 10px; background: #fff; z-index: 200; 
    p { margin: 0; }
}

.show-results .venues-results, 
.show-directions .venues-directions-form { left: 0; }

.venue-direction-panel-container,
.show-directions-instructions .venues-directions-form-2 { display: none; }
.show-directions-instructions .venue-direction-panel-container { display: block; }

.margin-map{ margin-left: 436px; }*/

/* venue transit tansport types*/
ul.transport-types {
    list-style: none;
    width: 100%;
    padding-left: 0;
    & li {
        display: inline-block;
        
        &  label {
            border: 1px solid #656D78;
            border-radius: 5px;
            padding: 6px 8px;
            width: auto;
            margin: 0 5px 0 0;
            cursor: pointer;
            color: #656D78;
        }
        & input[type="radio"]:checked + label {
            border: 1px solid #4A89DC;
            color: #4A89DC;
          } 
    }
    & li:last-child { margin: 0; }
}
/*
#debugThing { display: none; }*/