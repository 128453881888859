/*! Header */

.site-header { padding: 20px; position: fixed; left: 0; top: 0; z-index: 10; }
.site-logo { font-family: 'Suez One', serif; font-size: 3em; background: #fff; height: 50px; width: 50px; display: flex; align-items: center; justify-content: center; color: rgb(214, 85, 110); border-radius: 50%; text-decoration: none; }
@media screen and (min-width: $sm-width) {
    .site-logo { font-size: 6em; height: 90px; width: 90px; }
}

.home-panel { background-image: linear-gradient(19deg, rgb(214, 85, 110) 0%, rgb(31, 36, 161) 100%); height: 50vh; position: relative; display: flex; align-items: center; justify-content: center;
    h1 { color: #f6f6f6; font-family: 'Roboto', sans-serif; text-transform: uppercase; font-size: 40px;
        span { color: #fff; }
    }
}
@media screen and (min-width: $sm-width) {
    .home-panel { font-size: 60px; }    
}
.home-links { list-style: none; margin: 40px; display: flex; flex-wrap: wrap;
    li { padding: 20px; width: 50%; }
    a { display: flex; height: 200px; padding: 20px; color: #121212; text-decoration: none; font-family: 'Roboto', sans-serif; text-transform: uppercase; background: #fff; line-height: 1; align-items: flex-end; justify-content: flex-end; text-align: right; border-radius: .3rem; box-shadow: 1px 1px 1px rgba(0,0,0,.1); }
}
@media screen and (min-width: $sm-width) {
    .home-links { margin: 60px; 
        li { width: 20%; }
    }
}