/* Variables */

/*
Z-INDEX MAP

999 - Slick slides
1000 - Dropdown navigation
1300 - Fixed Header
1500 - Responsive navigation overlay
1501 - Responsive navigation trigger
1999 - Overlay BG
2000 - Overlays

*/

$sm-width: 480px;
$md-width: 768px; 
$lg-width: 1024px;
$xl-width: 1200px;

$sm-width-under: 479px;
$md-width-under: 767px;
$lg-width-under: 1023px;
$xl-width-under: 1199px;

$link-colour: #900;
$link-colour-hover: #000;

@mixin fade-transition {
    transition: background .25s ease-in-out, color .25s ease-in-out;
}

$form-input-border-radius: .5rem; 
$form-input-padding: 1rem; 
$form-input-border: .1rem solid #ccc;
$form-input-background-colour: #fff;
$form-input-border-focus: #79DCE8;
$form-input-transition: border-color .2s, box-shadow .2s;
$form-valid: #AEE88A;
$form-invalid: #FF665A;

$form-help-colour: #666;

$primary-colour: #d6556e;
$secondary-colour: #57A9CC;
$colour3: #B2495B;
$colour4: #FF8297;
$colour5: #FFF863;

$btn-default-colour: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;

$btn-primary-colour: #fff;
$btn-primary-bg: $primary-colour;
$btn-primary-border: darken($primary-colour, 10%);

$btn-primary-focus-colour: #fff;
$btn-primary-focus-bg: darken($btn-primary-bg, 5%);
$btn-primary-focus-border: darken($btn-primary-border, 5%);

$btn-padding: 10px 25px;